import { Grid, Typography } from "@mui/material";

export function TextWithIconBlock({ benefits }) {
  return (
    <Grid container spacing={4}>
      {benefits.map((benefit, index) => (
        <Grid item xs={12} md={4} key={index} textAlign="center">
          {/* <Card variant="outlined" sx={{p: 2}}> */}
          <Typography gutterBottom fontSize={50}>{benefit.icon}</Typography>
          <Typography gutterBottom variant="h3" fontWeight="bold">
            {benefit.title}
          </Typography>
          <Typography gutterBottom color="grey">{benefit.description}</Typography>
          {/* </Card> */}
        </Grid>
      ))}
    </Grid>
  );
}
