import { Grid } from "@mui/material";
import Image from "next/image";

export function Logos({ logos }) {
  return (
    <Grid container spacing={1}>
      {logos.map((logo, index) => (
        <Grid item xs={4} md={2} key={index}>
          <Image src={logo} width={125} height={125} style={{objectFit:'cover'}} />
        </Grid>
      ))}
    </Grid>
  );
}
