import { Container } from "@mui/material";
import { Hero } from "../components/Hero";

export default function HomePage() {

  return (
    <Container sx={{backgroundColor:"#fef3ea", mt: -4}}>
      <Hero />
    </Container>
  );
}
