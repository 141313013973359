import { Grid, Typography } from '@mui/material'
import { TextWithIconBlock } from './TextWithIconBlock'
import { Logos } from './Logos';
import { SearchSubmitContainer } from '../containers/SearchSubmitContainer';

export function Hero() {

  const benefits = [
      { title: 'Are you overspending on education supplies?', description: 'Check who offers the best prices. Compare prices from all major education suppliers on a single site', icon: "💸" },
      { title: 'Manage and approve purchase requests', description: 'Send paperless requisitions for finance to approve\n(coming soon)', icon: "✍️" },
      { title: 'Live catalogue from all major suppliers', description: 'Never order the wrong item again. Staff can tell finance exactly what they need by suggesting specific items from a live catalogue of products, from every major supplier.', icon: "🔄" },
  ]

  const logos=[
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Untitled+design+(10).png',
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Copy+of+Untitled+Design+(1).png',
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Untitled+design+(18).png',
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Untitled+design+(20).png',
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Untitled+design+(8).png',
      'https://bunsen-s3.s3.eu-west-2.amazonaws.com/public/school_logos/Untitled+design+(9).png',
  ]

  return (
    <Grid container sx={{height: {xs: "100%", md: "calc(100vh - 60px)" } }} spacing={{xs: 10, md:0}}>
        <Grid item xs={12} textAlign="center" alignSelf={"flex-end"} sx={{mt: {xs: 10, md:0}}}>
            <Typography fontSize={35} fontWeight="bold">Cut your education supplies </Typography>
            <Typography gutterBottom fontSize={35} fontWeight="bold">spend by 33%</Typography>
            <Typography mb={3} fontSize={18} color="grey" >Compare prices of 100,000s of education products from every supplier, on one site</Typography>
        <SearchSubmitContainer 
          button
          passThroughProps={{
            variant: "standard",
            sx:{
              boxShadow: "inset 0 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
              padding: "8px 8px 8px 24px",
              backgroundColor: "white",
              minWidth: "40%",
              maxWidth:{xs: "100%", md: "50%"},
          },
          }}
        />
        </Grid>
        <Grid item xs={12} textAlign="center" alignSelf="flex-end">
            <TextWithIconBlock benefits={benefits} />    
        </Grid>
        <Grid item xs={12} textAlign="center" alignSelf="flex-end">
            <Logos logos={logos} />
        </Grid>
    </Grid>
  );
}
